<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/label/topiclist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div style="
    width: 500px;
    margin-left: 200px;">
      <el-form ref="form" :model="form" label-width="180px">
          <el-form-item :required="true" label="一级分类名称">
            <el-input v-model="form.f_name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="一级分类英文名称">
            <el-input v-model="form.en_f_name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="排序">
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item :required="false" label="是否在前端展示">
            <el-checkbox v-model="form.is_show"></el-checkbox>
          </el-form-item>
          <el-form-item :required="false" label="上传图片">
            <el-upload
              class="avatar-uploader"
              action="#"
                :http-request="httpRequest"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          <div class="redtitle">请上传尺寸为240*162的JPG、png图片，大小在150kb内</div>
          </el-form-item>

          <el-form-item :required="true" label="二级分类">
            <div class="flex" style="margin-bottom:10px;" v-for="(item, index) in form.c_name"
          :key="index">
            <el-input style="margin-right:10px;" v-model="item.name"></el-input>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(index)"
            ></el-button>
          </div>
          
            <el-button
            style="margin-left:95px;"
              type="primary"
              icon="el-icon-plus"
              circle
              @click="add"
            ></el-button>
          </el-form-item>
        <el-form-item>
          <el-button style="margin-left:80px;" type="primary" @click="onSubmit">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      imageUrl: '',
      form: {
          f_name: '',
          en_f_name:'',
          sort: '',
          c_name:[{index:0,name:''}],
          is_show:'',
          images:'',
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
    handleAvatarSuccess(res, file) {
      console.log(res )
        this.imageUrl = URL.createObjectURL(file.raw);
      },httpRequest(data) {
            let file = data.file;
            const isImg = file.size / 1024  < 150
            if (!isImg) {
              this.$message.error('上传头像图片只能是150kb以内!');
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
              this.$message.error('上传头像图片只能是 JPG 或者 png格式!');
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name);
          axios.post(config.homeMenuUpload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              const newImg = new Image();
              newImg.src = response.data.data[0].images;
              newImg.onload = () => {
                if(newImg.width != 240 || newImg.height != 162){
                  _this.$message.error('图片的尺寸必须为240*162!');
                  return
                }
              _this.form.images = response.data.data[0].images;
              _this.imageUrl = response.data.data[0].images;
              _this.$message({
                message: '成功',
                type: 'success'
              });
              };
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      beforeAvatarUpload() {//file
        // const isJPG = file.type === 'image/jpeg';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
        return true
      },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      let arr = [];
      let length = postdata.c_name.length;
      for(let i in postdata.c_name){
          arr.push(postdata.c_name[i].name);
      }
      
      if(!postdata.f_name || !postdata.en_f_name){
        this.$alert("名称必填");
        return;
      }
       if(!postdata.sort){
        this.$alert("排序必填");
        return;
      }
      for(let i in postdata.c_name){
        if(!postdata.c_name[i].name){
          _this.$alert("二级分类名不能为空");
          return;
        }
          arr.push(postdata.c_name[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("标签名重复");
        return;
      }
      postdata.c_name = arr;
      postdata.is_show = postdata.is_show?1:0;
      axios.put(config.topicCategory,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '添加成功',
                type: 'success'
              });
              _this.goNav('/label/topiclist');
            }else{
              _this.form = {
                  f_name: '',
                  en_f_name:'',
                  sort: '',
                  c_name:[{index:0,name:''}],
                }
              _this.$alert(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },// 增加
    add() {
        console.log(this.form.c_name)
        this.form.c_name.push({
          index: this.form.c_name.length,
          name: ""
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.c_name.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.c_name.splice(index, 1);
      }
    },
  },
  created(){
      
  }
}
</script>
<style scoped>

.flex{
    display: flex;
}
.el-icon-close:before{
  font-size: 24px;
}
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .redtitle{
    color: red;
    line-height: 20px;
    font-size: 16px;
  }
</style>
